import DrawerAppbar from "../components/DrawerAppbar";
import useScrollToTop from "../hooks/useScrollToTop";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import useCallDataApi from "../hooks/data";
import Loading from "../components/Loading";
import { Container, Typography, List, ListItem, ListItemText, Link } from "@mui/material";

const Documents = () => {
    useScrollToTop();
    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState(false); // Fixed missing closing bracket
    const [documents, setDocuments] = useState([]);
    const { getData: fetchDocuments } = useCallDataApi("documents");

    useEffect(() => {
        setLoading(true);
        fetchDocuments()
            .then((data) => setDocuments(data))
            .catch((error) => console.error("Failed to fetch documents:", error)) // Optional: Error logging
            .finally(() => setLoading(false));
    }, []);

    document.title = `Jet Travel | ${t("documents.title")}`;

    return (
        <DrawerAppbar>
            <Container sx={{ marginTop: 10, marginBottom: 10 }}>
                <Typography variant="h2" gutterBottom textAlign="center">
                    {t("documents.headline")}
                </Typography>
                <Typography variant="body1" gutterBottom textAlign="center">
                    {t("documents.description")}
                </Typography>

                {/* Display documents */}
                <List>
                    {documents.map((doc) => (
                        <ListItem key={doc.id}>
                            <ListItemText
                                primary={doc.name}
                                secondary={
                                    <Link href={doc.document} target="_blank" rel="noopener noreferrer">
                                        {t("documents.download")}
                                    </Link>
                                }
                            />
                        </ListItem>
                    ))}
                </List>

                {/* Show loading spinner */}
                <Loading isLoading={loading} />
            </Container>
        </DrawerAppbar>
    );
};

export default Documents;
